import React from 'react';
import { Link } from 'gatsby';
import KnowledgeHubTabs from './KnowledgeHubTabs';
import { decodeEntities } from '../utils/htmlParse';
import '../acf/PageHeaderShort.scss';

const capitalizeWord = (word) => {
  return word.charAt(0).toUpperCase() + word.substr(1);
}

const capitalizePathname = (slug) => {
  return slug.split('-').map(word => capitalizeWord(word)).join(' ');
}

const getBreadCrumbs = (pathname) => {
  return pathname.replace(/^\/|\/$/g, '').split('/').map(slug => capitalizePathname(slug));
}

export const KnowledgeHubHeader = (props) => {
  const {
    location,
    activeTab = null,
    isCategoryPage,
    categoryName,
    title = "Knowledge Hub"
  } = props;
  const breadcrumbs = getBreadCrumbs(location.pathname);
  return (
    <section className="blog-header page-header-short">
      <div className="top">
        <div className="breadcrumbs">
          <div className="wrap">
            <Link className="crumb" to="/" aria-label="Home">Home</Link> /
            {/* eslint-disable-next-line */}
            {!isCategoryPage && breadcrumbs.map(breadcrumb => <span className="crumb"> {decodeEntities(breadcrumb)}</span>)}
            {isCategoryPage && (
              <span className="crumb"> {decodeEntities(categoryName)}</span>
            )}
          </div>
        </div>
        <div className="title">
          <div className="wrap">
            {isCategoryPage ?
              <span>{decodeEntities(title)}</span> :
              <h1>{decodeEntities(title)}</h1>
            }

          </div>
        </div>
      </div>
      <div className="wrap">
        <div className="subtitle extra-padding">Select a category:</div>
        <KnowledgeHubTabs activeTab={activeTab} />
      </div>
    </section>
  );
}
