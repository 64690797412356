import React from 'react'
import { Link } from 'gatsby'
import './Pagination.scss';

const prefixZero = (pageNumber) => {
  return pageNumber < 10 ? `0${pageNumber}` : pageNumber;
}

const Pagination = ({ pageContext, pathPrefix }) => {
  if (!pageContext) return null;
  const { previousPagePath, nextPagePath, humanPageNumber: currentPageNumber, numberOfPages } = pageContext;
  const pageNumbersArray = Array.from(Array(numberOfPages).keys()).map((val, index) => index + 1);
  if (numberOfPages === 1) return null;
  return (
    <nav className="pagination" role="navigation">
      {previousPagePath && (
        <div className="arrow prev">
          <Link to={`/${previousPagePath}/`} rel="prev" aria-label="Previous">
            Previous
          </Link>
        </div>
      )}
      <div className="pages">
        {pageNumbersArray.map((pageNumber) => {
          if (pageNumber === currentPageNumber) {
            return <div className="page-num active"><span>{prefixZero(pageNumber)}</span></div>;
          }
          return (
            <div className="page-num">
              <Link to={pageNumber === 1 ? `/${pathPrefix}/` : `/${pathPrefix}/page/${pageNumber}/`} aria-label={prefixZero(pageNumber)}>
                {prefixZero(pageNumber)}
              </Link>
            </div>
          );
        })}
      </div>
      {nextPagePath && (
        <div className="arrow next">
          <Link to={`/${nextPagePath}/`} rel="next" aria-label="Next">
            Next
          </Link>
        </div>
      )}
    </nav>
  )
}

export default Pagination
