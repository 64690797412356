import React, { Component } from 'react';
import { withPrefix } from 'gatsby';
import Img from 'gatsby-image';
import './Image.scss';

export default class Image extends Component {
  state = {
    imageFullyLoaded: false
  }

  handleImageOnLoad = () => {
    this.setState({ imageFullyLoaded: true });
  }

  render() {
    const { imageFullyLoaded } = this.state;
    const { placeholder = false, image, className = '' } = this.props;
    if (!image || placeholder) return <img className={`gatsby-image placeholder ${className}`} src={withPrefix('placeholder.jpg')} alt="" />;
    if (
      image.localFile && image.localFile.childImageSharp && image.localFile.childImageSharp.fluid
    ) {
      return <Img onLoad={this.handleImageOnLoad} className={`gatsby-image ${className} ${imageFullyLoaded ? 'loaded' : ''}`} fluid={image.localFile.childImageSharp.fluid} />;
    }
    if (image.source_url) {
      return <img className={`gatsby-image ${className}`} src={image.source_url} alt={image.alt || ''} />;
    }
    if (image.url) {
      return <img className={`gatsby-image ${className}`} src={image.url} alt={image.alt || ''} />;
    }
    return <img className={`gatsby-image placeholder ${className}`} src={withPrefix('placeholder.jpg')} alt="" />;
  }
}
