import React, { Component } from 'react';
import { Link, graphql, StaticQuery, navigate } from 'gatsby';
import { SearchIcon } from './SearchIcon';
import './KnowledgeHubTabs.scss';

const VIDEOS_SLUG = 'videos';
const WHITEPAPERS_SLUG = 'whitepapers';
const BROCHURES_SLUG = 'brochures';

class KnowledgeHubTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchActive: false,
      searchTerm: '',
      showFilters: false
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleEscKey, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEscKey, false);
  }

  handleEscKey = (event) => {
    const { searchActive } = this.state;
    if (event.keyCode === 27 && searchActive) this.toggleSearchBar();
  }

  showFiltersOnMobile = () => {
    const { showFilters } = this.state;
    return this.setState({ showFilters: !showFilters });
  }

  toggleSearchBar = () => {
    const { searchActive } = this.state;
    setTimeout(() => {
      if (this.searchInput) this.searchInput.focus();
    }, 100);
    return this.setState({ searchActive: !searchActive });
  }

  onChangeHandler = (event) => {
    return this.setState({ searchTerm: event.target.value });
  }

  onGlobalSearchSubmit = (event) => {
    event.preventDefault();
    const { searchActive, searchTerm } = this.state;
    this.setState({ searchActive: !searchActive });
    return navigate(`/search?query=${searchTerm}&origin=hub`);
  }

  getActiveTabLabel = () => {
    const { activeTab, data } = this.props;
    const {
      site: {
        siteMetadata: {
          blogSlug
        }
      }
    } = data;
    switch (activeTab) {
      case blogSlug:
        return 'Articles';
      case VIDEOS_SLUG:
        return 'Videos';
      case BROCHURES_SLUG:
        return 'Brochures';
      case WHITEPAPERS_SLUG:
        return 'Whitepapers';
      default:
        return 'Knowledge Hub Home';
    }
  }

  render() {
    const {
      activeTab = null,
      data
    } = this.props;
    const {
      site: {
        siteMetadata: {
          blogSlug,
          publicationsSlug
        }
      }
    } = data;
    const {
      searchActive,
      // searchTerm,
      showFilters,
    } = this.state;
    return (
      <div className="knowledgehub-tabs">
        <div className="tab-container">
          <form className="hub-search-form" onSubmit={this.onGlobalSearchSubmit}>
            <button
              type="button"
              className="search-toggle"
              onClick={() => this.toggleSearchBar()}
              aria-label="Open Search"
            >
              <SearchIcon strokeColor="#817B71" />
            </button>
            <input
              type="text"
              onChange={this.onChangeHandler}
              placeholder="Search by name"
              className={`search-bar ${searchActive ? 'active' : ''}`}
            />
          </form>
          <button
            type="button"
            className={`tab show-filters active ${showFilters ? 'open' : 'closed'}`}
            onClick={this.showFiltersOnMobile}
            aria-label={this.getActiveTabLabel()}
          >
            {this.getActiveTabLabel()}
          </button>
          <Link
            className={`tab ${activeTab === null ? 'active' : ''} ${showFilters ? '' : 'home small-tablet-up'}`}
            to="/knowledge-hub/"
            aria-label="Knowledge Hub Home"
          >
            Knowledge Hub Home
          </Link>
          <Link
            className={`tab ${activeTab === 'blog' ? 'active' : ''} ${showFilters ? '' : 'small-tablet-up'}`}
            to={`/${blogSlug}/`}
            aria-label="Articles"
          >
            Articles
          </Link>
          <Link
            className={`tab ${activeTab === 'videos' ? 'active' : ''} ${showFilters ? '' : 'small-tablet-up'}`}
            to={`/${publicationsSlug}/category/${VIDEOS_SLUG}/`}
            aria-label="Videos"
          >
            Videos
          </Link>
          <Link
            className={`tab ${activeTab === 'brochures' ? 'active' : ''} ${showFilters ? '' : 'small-tablet-up'}`}
            to={`/${publicationsSlug}/category/${BROCHURES_SLUG}/`}
            aria-label="Brochures"
          >
            Brochures
          </Link>
          {/* HIDING TEMPORARILY, UNTIL THERE ARE WHITEPAPERS TO SHOW */}
          {/* <Link
            className={`tab ${activeTab === 'whitepapers' ? 'active' : ''} ${showFilters ? '' : 'small-tablet-up'}`}
            to={`/${publicationsSlug}/category/${WHITEPAPERS_SLUG}/`}
            aria-label="Whitepapers"
          >
            Whitepapers
          </Link> */}
          {/* <button
            type="button"
            className={`tab ${showFilters ? '' : 'small-tablet-up'}`}
            aria-label="Wealth with Purpose Ebook"
          >
            Wealth with Purpose Ebook
          </button> */}
        </div>
      </div>
    );
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            blogSlug
            publicationsSlug
          }
        }
      }
    `}
    render={data => <KnowledgeHubTabs data={data} {...props} />}
  />
)
