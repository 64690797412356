import React from 'react';
import { navigate } from 'gatsby';
import './FilterSelect.scss';

const navigateToCategory = (event, pathPrefix) => {
  return navigate(`${pathPrefix}${event.target.value ? `/category/${event.target.value}` : ''}`)
}

export const CategoryFilter = (props) => {
  const { pathPrefix, categories, activeFilter } = props;
  return (
    <select className="filter-select" onChange={(event) => navigateToCategory(event, pathPrefix)}>
      <option value="" selected>All</option>
      {categories && categories.map(({node: category}) => {
        return (
          <option value={category.slug} selected={activeFilter === category.slug} dangerouslySetInnerHTML={{__html: category.name}} />
        );
      })}
    </select>
  );
}
